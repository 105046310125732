body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: rgba(1, 1, 1, .01);
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.section-1 {
    background-image: url(../images/shape.png);
    background-repeat: no-repeat;
    background-size: 45% 100%;
    height: 100%;
    width: 100%;
    opacity: 0.2;
}

.section-2 {
    background-image: url(../images/section-2.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.05;
}

.section-3 {
    background-image: url(../images/section-3.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.1;
}

.section-4 {
    background-image: url(../images/section-4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.6;
}

.laptop-image {
    background: url(../images/laptop-screen.png) center center no-repeat;
    background-size: 95%;
    background-position: 17px 16%;

    // background-size: 65%;
    // background-position: center 30%;
}

.mobile-image {
    background: url(../images/mobile-inner.png) center center no-repeat;
    background-size: 87%;
    background-position: center 55%;
    // border-radius: 19%;
    // width: 28%;
    top: 120px;
    left: -4%;

    &.android {
        background-size: 90% !important;
        background-position: center 33px !important;
    }
}

.ipad-image {
    background: url(../images/ipad-inner.png) center center no-repeat;

    background-size: 88% 90%;
    background-position: center 47%;
    max-width: 95%;
    // background-size: 88%;
    // background-position: center 40%;
    // max-width: 95%;
}

.desktop-image {
    background: url(../images/upload.png) center center no-repeat;
    background-size: 95%;
    background-position: center 28%;
}

.ipad-image-history {
    background: url(../images/history.png) center center no-repeat;
    background-size: 93%;
    background-position: center 51%;
    max-width: 95%;
    top: -16%;
    transform: scale(0.6);
}

.grow-1 {
    flex: 1;
}

.box-img-ab {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 15px;
    background: #fff;
    border-radius: 50%;
}

.store-btn {
    background: url(../images/download.png) no-repeat;
    background-position: -416px -48px;
    display: inline-block;
    width: 315px;
    height: 100px;
    text-indent: -10000000px;
    transform: scale(.7);

    &.ios {
        background-position: -57px -48px;
    }
}

// .custom-box {
//     width: calc((80rem / 5) - 20px);
//     height: calc((80rem / 5) - 20px);
//     margin-bottom: 30px;
//     flex-direction: column;

//     &.small-box {
//         width: calc((70rem / 3) - 7rem);
//         height: calc((70rem / 3) - 7rem);
//         // width: calc((100% / 3) - 5%);
//         // height: calc((100% / 3) - 5%);
//     }
// }

@media (min-width: 768px) {
    .full-height {
        min-height: calc(100vh - 80px);
    }
}

@media (max-width: 550px) {
    .custom-box {
        width: 80%
    }
}

@media (max-width: 450px) {
    .custom-box {
        width: 95%;
    }
}